import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPage = ({ location }) => (
  <Layout>
    <SEO title="Privacy Policy" currentUrl={location.pathname} />
    <div className={"container"}>
      <div className={"content"}>
        <div className={"title"}>
          <h1>Privacy Policy</h1>
        </div>
        <p>
          If you have additional questions or require more information about the
          Privacy Policy, do not hesitate to contact me through email at&nbsp;
          <a href="mailto:hi.mcpiper@oleggera.com">hi.mcpiper@oleggera.com</a>
        </p>

        <h3>Log Files</h3>
        <p>
          McPiper follows a standard procedure of using log files. These files
          log visitors when they visit websites. All hosting companies do this
          and a part of hosting services' analytics. The information collected
          by log files include internet protocol (IP) addresses, browser type,
          Internet Service Provider (ISP), date and time stamp, referring/exit
          pages, and possibly the number of clicks. These are not linked to any
          information that is personally identifiable. The purpose of the
          information is for analyzing trends, administering the site, tracking
          users' movement on the website, and gathering demographic information.
        </p>

        <h3>In App Analytics</h3>
        <p>
          McPiper uses <a href="https://appcenter.ms">App Center</a> for app
          crash reporting, in app analytics and beta app distribution. Once you
          fill in the form for joining beta program, your email will be added in
          App Center. When running the application, no personal identifiable
          information is sent to App Center.
        </p>

        {/* <h3>Cookies and Web Beacons</h3>
        <p>
          Like any other website, HiStaff uses 'cookies'. These cookies are used
          to store information including visitors' preferences, and the pages on
          the website that the visitor accessed or visited. The information is
          used to optimize the users' experience by customizing our web page
          content based on visitors' browser type and/or other information.
        </p> */}

        <h3>Third Party Privacy Policies</h3>
        <p>
          McPiper's Privacy Policy does not apply to other advertisers or
          websites. Thus, we are advising you to consult the respective Privacy
          Policies of these third-party ad servers for more detailed
          information. It may include their practices and instructions about how
          to opt-out of certain options. You may find a complete list of these
          Privacy Policies and their links here: Privacy Policy Links.
        </p>
        <p>
          You can choose to disable cookies through your individual browser
          options. To know more detailed information about cookie management
          with specific web browsers, it can be found at the browsers'
          respective websites. What Are Cookies?
        </p>

        <h3>Children's Information</h3>
        <p>
          Another part of our priority is adding protection for children while
          using the internet. We encourage parents and guardians to observe,
          participate in, and/or monitor and guide their online activity.
        </p>
        <p>
          McPiper does not knowingly collect any Personal Identifiable
          Information from children under the age of 13. If you think that your
          child provided this kind of information on our website, we strongly
          encourage you to contact us immediately and we will do our best
          efforts to promptly remove such information from our records.
        </p>

        <h3>Online Privacy Policy Only</h3>
        <p>
          This Privacy Policy applies only to our online activities and is valid
          for visitors to our website with regards to the information that they
          shared and/or collect in McPiper. This policy is not applicable to any
          information collected offline or via channels other than this website.
        </p>

        <h3>Consent</h3>
        <p>
          By using our website, you hereby consent to our Privacy Policy and
          agree to its Terms and Conditions.
        </p>
      </div>
    </div>
  </Layout>
)

export default PrivacyPage
